import React from "react";
import {
  facebook,
  logo__footer,
  twitter,
  youtube,
  instagram,
} from "src/assets/images";
import CopyWrite from "./CopyWrite";
import NewsLettre from "./NewsLettre";
import { Link } from "react-router-dom";
import { i18n } from "../../i18n";

export default function MegaFooter() {
  return (
    <>
      <NewsLettre />
      <div className="app__footer">
        <div className="footer">
          <div className="footer__logo">
            <img src={logo__footer} alt="" />
            <div className="footer__content detaill__footer">
              {i18n("common.FTDES")}
            </div>
          </div>
          <div className="hr__bar"></div>
          <div className="footer__menu">
            <div className="label__footer">{i18n("common.Menue")}</div>
            <div className="footer__content content__menu">
              <div>
                <ul>
                  <li className="liClass">
                    <Link className="footerLink" to="/A_propos">
                      {i18n("menu.propos")}
                    </Link>
                  </li>
                  <li className="liClass">
                    <Link className="footerLink" to="/Archives">
                      {i18n("menu.Archives")}
                    </Link>
                  </li>
                  <li className="liClass">
                    <Link className="footerLink" to="/Glossaire">
                      {i18n("menu.Glossaire")}
                    </Link>
                  </li>
                  <li className="liClass">
                    <Link className="footerLink" to="/publication">
                      {i18n("menu.Publications")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li className="liClass">
                    <Link className="footerLink" to="/evenement">
                      {i18n("menu.Evènements")}
                    </Link>
                  </li>
                  <li className="liClass">
                    <Link className="footerLink" to="/Contact">
                      {i18n("common.Contact")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="hr__bar"></div>
          <div className="footer__socialmedia">
            <div className="label__footer">{i18n("common.social_media")}</div>
            <div className="footer__content content__socialmedia">
              <a href="https://www.facebook.com/ftdes">
                <div style={{ color: "white", fontFamily: "Proxima Nova" }}>
                  <img
                    className="lazyload"
                    src={facebook}
                    alt=""
                    style={{ paddingRight: 20, verticalAlign: "middle" }}
                  />
                  Facebook
                </div>
              </a>
              <a href="https://twitter.com/FT_DES">
                <div style={{ color: "white", fontFamily: "Proxima Nova" }}>
                  <img
                    className="lazyload"
                    src={twitter}
                    alt=""
                    style={{ paddingRight: 20, verticalAlign: "middle" }}
                  />
                  Twitter
                </div>
              </a>
              <a href="https://www.youtube.com/@FTDESTunisie">
                <div style={{ color: "white", fontFamily: "Proxima Nova" }}>
                  <img
                    className="lazyload"
                    src={youtube}
                    alt=""
                    style={{ paddingRight: 20, verticalAlign: "middle" }}
                  />
                  Youtube
                </div>
              </a>
              <a href="https://www.instagram.com/ftdes_tunisie/?hl=fr">
                <div style={{ color: "white", fontFamily: "Proxima Nova" }}>
                  <img
                    className="lazyload"
                    src={instagram}
                    alt=""
                    style={{ paddingRight: 20, verticalAlign: "middle" }}
                  />
                  Instagram
                </div>
              </a>
            </div>
          </div>
          <div className="hr__bar"></div>
          <div className="footer__contact">
            <div className="label__footer">{i18n("common.Contact")}</div>
            <div className="footer__content contact__desctioption">
              {i18n("common.address")}
            </div>
            <div className="footer__content contact__desctioption">
              {i18n("common.email")}
            </div>
            <div className="footer__content contact__desctioption">
              {i18n("common.phone")}
            </div>
          </div>
        </div>
      </div>
      <CopyWrite />
    </>
  );
}
