import { ConnectedRouter } from "connected-react-router";
import "lazysizes";
import "./App.css";
import { configureStore, getHistory } from "src/modules/store";
import { Provider } from "react-redux";
import RoutesComponent from "src/view/shared/routes/RoutesComponent";
import TenantService from "src/modules/Tenant/TenantService";
import ReactDOM from 'react-dom';
import React, { useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.initialize(TRACKING_ID);
  const TRACKING_ID = "G-046BCM3NVM"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
const store = configureStore();
const App = (props) => {
  TenantService.getTenants().then((res) => {
    localStorage.setItem("tenant", JSON.stringify(res.rows[0]));
  });
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Provider store={store}>
      <ConnectedRouter history={getHistory()}>
        <RoutesComponent />
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
