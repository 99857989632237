import DiscussionService from "src/modules/discussion/discussionService";
import Errors from "src/modules/shared/error/errors";
import Message from "src/view/shared/message";
import { getHistory } from "src/modules/store";
import { i18n } from "src/i18n";

const prefix = "DISCUSSION_FORM";

const discussionFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: discussionFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await DiscussionService.find(id);
      }

      dispatch({
        type: discussionFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: discussionFormActions.INIT_ERROR,
      });

      getHistory().push("/discussion");
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: discussionFormActions.CREATE_STARTED,
      });

      await DiscussionService.create(values);

      dispatch({
        type: discussionFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n("entities.discussion.create.success"));

      // getHistory().push('/discussion');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: discussionFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: discussionFormActions.UPDATE_STARTED,
      });

      await DiscussionService.update(id, values);

      dispatch({
        type: discussionFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n("entities.discussion.update.success"));

      // getHistory().push('/discussion');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: discussionFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default discussionFormActions;
