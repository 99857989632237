import React from "react";
import { i18n } from "../../i18n";
import { Link } from "react-router-dom";

function CopyWrite() {
  const language = localStorage.getItem("language");

  return (
    <div className="footer__">
      <div className="footer__copywrite">
        {language === "fr" ? (
          <div className="copywrite">
            <div className="copywrite__right">
              <ul>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/contact"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.Contact")}
                  </Link>
                </li>

                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/terms_use"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.condition_utilisation")}
                  </Link>
                </li>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/legal_notice"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.mention_legal")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="copywrite__left">{i18n("common.copyright")}</div>
          </div>
        ) : language === "en" ? (
          <div className="copywrite">
            <div className="copywrite__right">
              <ul>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/contact"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.Contact")}
                  </Link>
                </li>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/terms_use"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.condition_utilisation")}
                  </Link>
                </li>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/legal_notice"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.mention_legal")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="copywrite__left">{i18n("common.copyright")}</div>
          </div>
        ) : (
          <div className="copywrite">
            <div className="copywrite__left">
              <ul>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/contact"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.Contact")}
                  </Link>
                </li>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/terms_use"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.condition_utilisation")}
                  </Link>
                </li>
                <li className="liClass">
                  <Link
                    className="footerLink"
                    to="/legal_notice"
                    style={{ color: "#fff" }}
                  >
                    {i18n("common.mention_legal")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="copywrite__left">{i18n("common.copyright")}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CopyWrite;
