import list from "src/modules/discussion/list/discussionListReducers";
import form from "src/modules/discussion/form/discussionFormReducers";
import view from "src/modules/discussion/view/discussionViewReducers";
import destroy from "src/modules/discussion/destroy/discussionDestroyReducers";
import { combineReducers } from "redux";

export default combineReducers({
  list,
  form,
  view,
  destroy,
});
